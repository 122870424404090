import { Formik } from "formik";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ErrorMessage, Radio } from "@components/atoms";
import { Money } from "@components/containers";
import { checkoutMessages } from "@temp/intl";
import * as S from "./styles";

/**
 * Shipping method selector used in checkout.
 */
var CheckoutShipping = function CheckoutShipping(_ref) {
  var _checkout$shippingAdd, _checkout$shippingAdd2, _checkout$shippingAdd3;

  var shippingMethods = _ref.shippingMethods,
      selectedShippingMethodId = _ref.selectedShippingMethodId,
      selectShippingMethod = _ref.selectShippingMethod,
      errors = _ref.errors,
      formId = _ref.formId,
      formRef = _ref.formRef,
      checkout = _ref.checkout;

  /* ZIP Codes for Campos dos Goytacazes */
  var localStartCode = 28000001;
  var localFinalCode = 28179999;
  var postalCode = parseInt((checkout === null || checkout === void 0 ? void 0 : (_checkout$shippingAdd = checkout.shippingAddress) === null || _checkout$shippingAdd === void 0 ? void 0 : _checkout$shippingAdd.postalCode) || "0");
  var countryArea = (checkout === null || checkout === void 0 ? void 0 : (_checkout$shippingAdd2 = checkout.shippingAddress) === null || _checkout$shippingAdd2 === void 0 ? void 0 : (_checkout$shippingAdd3 = _checkout$shippingAdd2.countryArea) === null || _checkout$shippingAdd3 === void 0 ? void 0 : _checkout$shippingAdd3.toUpperCase()) || "RJ";
  var shippingByState = {};
  shippingMethods === null || shippingMethods === void 0 ? void 0 : shippingMethods.map(function (_ref2, index) {
    var _shippingArray$;

    var name = _ref2.name;
    var shippingArray = name.split("-");
    var countryState = (_shippingArray$ = shippingArray[2]) === null || _shippingArray$ === void 0 ? void 0 : _shippingArray$.replace(" ", "");
    if (!(countryState in shippingByState)) shippingByState[countryState] = [];
    shippingByState[countryState].push(name);
  });
  var filteredMethods = shippingMethods === null || shippingMethods === void 0 ? void 0 : shippingMethods.filter(function (method) {
    return shippingByState[countryArea].includes(method.name);
  });
  return React.createElement("section", null, React.createElement(S.Title, {
    "data-test": "checkoutPageSubtitle"
  }, React.createElement(FormattedMessage, checkoutMessages.shippingMethod)), React.createElement(Formik, {
    initialValues: {
      shippingMethod: selectedShippingMethodId
    },
    enableReinitialize: true,
    onSubmit: function onSubmit(values, _ref3) {
      var setSubmitting = _ref3.setSubmitting;

      if (selectShippingMethod && values.shippingMethod) {
        selectShippingMethod(values.shippingMethod);
      }

      setSubmitting(false);
    }
  }, function (_ref4) {
    var handleChange = _ref4.handleChange,
        handleSubmit = _ref4.handleSubmit,
        handleBlur = _ref4.handleBlur,
        values = _ref4.values,
        setFieldValue = _ref4.setFieldValue,
        setFieldTouched = _ref4.setFieldTouched;
    return React.createElement(S.ShippingMethodForm, {
      id: formId,
      ref: formRef,
      onSubmit: handleSubmit
    }, filteredMethods.map(function (_ref5, index) {
      var id = _ref5.id,
          name = _ref5.name,
          price = _ref5.price;
      var checked = !!values.shippingMethod && values.shippingMethod === id;
      /* Don't show take away and bike express outside of the city. */

      if (!(postalCode >= localStartCode && postalCode <= localFinalCode) && ["take away - cb - rj", "bike express - cb - rj"].includes(name.toLowerCase())) {
        return;
      }

      return React.createElement(S.Tile, {
        checked: checked,
        key: id,
        "data-test": "shippingMethodTile",
        "data-test-id": id
      }, React.createElement(Radio, {
        name: "shippingMethod",
        value: id,
        checked: checked,
        customLabel: true,
        onChange: function onChange() {
          return setFieldValue("shippingMethod", id);
        }
      }, React.createElement(S.TileTitle, null, React.createElement("span", {
        "data-test": "checkoutShippingMethodOptionName"
      }, name), React.createElement(S.Price, null, " ", "| +", React.createElement(Money, {
        "data-test": "checkoutShippingMethodOptionPrice",
        money: price
      })))));
    }), React.createElement(ErrorMessage, {
      errors: errors
    }));
  }));
};

export { CheckoutShipping };
/* eslint-disable global-require */
import { generatePageUrl } from "./utils";
export var BASE_URL = "/";
export var PRODUCTS_PER_PAGE = 6;
export var SUPPORT_EMAIL = "support@example.com";
export var PROVIDERS = {
  BRAINTREE: {
    label: "Braintree"
  },
  DUMMY: {
    label: "Dummy"
  },
  STRIPE: {
    label: "Cartão de Crédito/Débito"
  },
  ADYEN: {
    label: "Adyen",
    script: {
      src: "https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/3.10.1/adyen.js",
      integrity: "sha384-wG2z9zSQo61EIvyXmiFCo+zB3y0ZB4hsrXVcANmpP8HLthjoQJQPBh7tZKJSV8jA",
      crossOrigin: "anonymous"
    },
    style: {
      src: "https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/3.10.1/adyen.css",
      integrity: "sha384-8ofgICZZ/k5cC5N7xegqFZOA73H9RQ7H13439JfAZW8Gj3qjuKL2isaTD3GMIhDE",
      crossOrigin: "anonymous"
    }
  }
};
export var STATIC_PAGES = [{
  label: "Sobre Nós",
  url: generatePageUrl("about")
}];
export var SOCIAL_MEDIA = [{
  ariaLabel: "facebook",
  href: "https://www.facebook.com/clubebaristaoficial/",
  path: require("../images/facebook-icon.svg")
}, {
  ariaLabel: "instagram",
  href: "https://www.instagram.com/clubebarista/",
  path: require("../images/instagram-icon.svg")
}];
export var META_DEFAULTS = {
  custom: [],
  description: "Storefront for the Clube Barista e-commerce platform",
  image: "".concat(window.location.origin).concat(require("../images/favicon_xicara.svg")),
  title: "Clube Barista - Loja",
  type: "website",
  url: window.location.origin
};
export var CheckoutStep;

(function (CheckoutStep) {
  CheckoutStep[CheckoutStep["Address"] = 1] = "Address";
  CheckoutStep[CheckoutStep["Shipping"] = 2] = "Shipping";
  CheckoutStep[CheckoutStep["Payment"] = 3] = "Payment";
  CheckoutStep[CheckoutStep["Review"] = 4] = "Review";
  CheckoutStep[CheckoutStep["PaymentConfirm"] = 5] = "PaymentConfirm";
})(CheckoutStep || (CheckoutStep = {}));

export var CHECKOUT_STEPS = [{
  index: 0,
  link: "/checkout/address",
  name: "Address",
  nextActionName: "Continue to Shipping",
  onlyIfShippingRequired: false,
  step: CheckoutStep.Address
}, {
  index: 1,
  link: "/checkout/shipping",
  name: "Shipping",
  nextActionName: "Continue to Payment",
  onlyIfShippingRequired: true,
  step: CheckoutStep.Shipping
}, {
  index: 2,
  link: "/checkout/payment",
  name: "Payment",
  nextActionName: "Continue to Review",
  onlyIfShippingRequired: false,
  step: CheckoutStep.Payment
}, {
  index: 3,
  link: "/checkout/review",
  name: "Review",
  nextActionName: "Place order",
  onlyIfShippingRequired: false,
  step: CheckoutStep.Review
}, {
  index: 4,
  link: "/checkout/payment-confirm",
  name: "Payment confirm",
  onlyIfShippingRequired: false,
  step: CheckoutStep.PaymentConfirm,
  withoutOwnView: true
}];